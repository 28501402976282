<template>
  <div>
    <el-row>
      <el-col :span="24" style="height: 60px; line-height: 60px; background: #000000; padding: 0 25%;">
        <div class="nav">
          <img :src="head_logo" alt="logo" class="img_resize" style="margin-top: -12px; margin-right: 30px;">
          <div class="nav_menu">
            <router-link to="/home" @click.native.prevent="showGoods">车机产品</router-link>
            <router-link to="/user">手机支架</router-link>
            <router-link to="/user">行车记录</router-link>
            <router-link to="/user">胎压监测</router-link>
            <router-link to="/home/support">支持</router-link>
          </div>
          <el-button type="primary" size="mini" style="margin-left: 200px; transform: translateY(-4px);">商城</el-button>
          <span class="el-icon-search search"></span>
          <!-- <span class="el-icon-search search" @mouseover="searchMove"></span> -->
          <!-- <el-input v-model="input" placeholder="请输入内容" class="ipt" @mouseleave.native="iptLeave"></el-input> -->
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" style="background: #000000; padding: 0 25%;">
        <div class="grid-content bg-purple-dark">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="概述" name="first">
              <img :src="img_one" alt="1" class="aio_img_resize">
              <img :src="img_two" alt="2" class="aio_img_resize">
              <img :src="img_three" alt="3" class="aio_img_resize">
              <img :src="img_four" alt="4" class="aio_img_resize">
              <img :src="img_five" alt="5" class="aio_img_resize">
              <img :src="img_six" alt="6" class="aio_img_resize">
              <img :src="img_seven" alt="7" class="aio_img_resize">
              <img :src="img_eight" alt="8" class="aio_img_resize">
              <img :src="img_night" alt="9" class="aio_img_resize">
            </el-tab-pane>
            <el-tab-pane label="常见问题" name="second">

            </el-tab-pane>
            <el-tab-pane label="规格" name="third">
              <img :src="img_top" alt="1" class="aio_img_resize" style="margin-top: 60px;">
              <ul class="specs">
                <li>
                  <span class="title">概要</span>
                  <div class="info">
                    <div>
                      <p style="color: grey;">产品型号</p>
                      <p style="color: #fff;">AIO-5</p>
                    </div>
                    <div>
                      <p style="color: grey;">处理器</p>
                      <p style="color: #fff;">ARM 2.0GHZ 8核</p>
                    </div>
                    <div>
                      <p style="color: grey;">运存</p>
                      <p style="color: #fff;">3GB</p>
                    </div>
                    <div>
                      <p style="color: grey;">内存</p>
                      <p style="color: #fff;">32GB</p>
                    </div>
                    <div>
                      <p style="color: grey;">扩展</p>
                      <p style="color: #fff;">128G高速TF卡</p>
                    </div>
                    <div>
                      <p style="color: grey;">定位</p>
                      <p style="color: #fff;">北斗+GPS</p>
                    </div>
                  </div>
                </li>
                <li>
                  <span class="title">网络与传输</span>
                  <div class="info">
                    <div>
                      <p style="color: grey;">433M无线通讯</p>
                      <p style="color: #fff;">支持</p>
                    </div>
                    <div>
                      <p style="color: grey;">移动网络</p>
                      <p style="color: #fff;">4G LTE全网通</p>
                    </div>
                    <div>
                      <p style="color: grey;">蓝牙</p>
                      <p style="color: #fff;">Bluetooth 5.0</p>
                    </div>
                    <div>
                      <p style="color: grey;">WLAN</p>
                      <p style="color: #fff;">2.4G/5G双频Wi-Fi</p>
                    </div>
                    <div>
                      <p style="color: grey;">传输速度</p>
                      <p style="color: #fff;">30MB/s</p>
                    </div>
                  </div>
                </li>
                <li>
                  <span class="title">屏幕</span>
                  <div class="info">
                    <div>
                      <p style="color: grey;">屏幕亮度</p>
                      <p style="color: #fff;">> 800NIT</p>
                    </div>
                    <div>
                      <p style="color: grey;">屏幕规格</p>
                      <p style="color: #fff;">5寸 1280x720 IPS</p>
                    </div>
                  </div>
                </li>
                <li>
                  <span class="title">视频</span>
                  <div class="info">
                    <div>
                      <p style="color: grey;">图像传感器</p>
                      <p style="color: #fff;">SONY IMAX307</p>
                    </div>
                    <div>
                      <p style="color: grey;">镜头</p>
                      <p style="color: #fff;">F1.8光圈 6P全玻璃片</p>
                    </div>
                    <div>
                      <p style="color: grey;">镜头角度</p>
                      <p style="color: #fff;">150° 广角</p>
                    </div>
                    <div>
                      <p style="color: grey;">是否防抖</p>
                      <p style="color: #fff;">智能防抖</p>
                    </div>
                    <div>
                      <p style="color: grey;">录制模式</p>
                      <p style="color: #fff;">前后双摄像头</p>
                    </div>
                    <div>
                      <p style="color: grey;">录制格式</p>
                      <p style="color: #fff;">1080P 30FPS</p>
                    </div>
                    <div>
                      <p style="color: grey;">视频格式</p>
                      <p style="color: #fff;">MP4</p>
                    </div>
                    <div>
                      <p style="color: grey;">昼夜模式</p>
                      <p style="color: #fff;">自动切换</p>
                    </div>
                  </div>
                </li>
                <li>
                  <span class="title">OBD数据</span>
                  <div class="info">
                    <div>
                      <p style="color: grey;">OBD实时数据</p>
                      <p style="color: #fff;">选配</p>
                    </div>
                    <div>
                      <p style="color: grey;">OBD数据叠加</p>
                      <p style="color: #fff;">可叠加于行车录像中（选配)</p>
                    </div>
                    <div>
                      <p style="color: grey;">OBD故障诊断</p>
                      <p style="color: #fff;">选配</p>
                    </div>
                    <div>
                      <p style="color: grey;">传感器</p>
                      <p style="color: #fff;">光线、地磁、气压、陀螺仪、加速</p>
                      <p style="color: #fff; margin-top: -10px;">度、麦克风</p>
                    </div>
                    <div>
                      <p style="color: grey;">专业仪表</p>
                      <p style="color: #fff;">可自定义</p>
                    </div>
                    <div>
                      <p style="color: grey;">远程防盗</p>
                      <p style="color: #fff;">高音报警喇叭、摄像头即时
                        拍照回传</p>
                    </div>
                    <div>
                      <p style="color: grey;">远程服务</p>
                      <p style="color: #fff;">支持远程APP监测</p>
                    </div>
                    <div>
                      <p style="color: grey;">保养提醒</p>
                      <p style="color: #fff;">APP支持</p>
                    </div>
                  </div>
                </li>
                <li>
                  <span class="title">OBD数据</span>
                  <div class="info">
                    <div>
                      <p style="color: grey;">防震结构</p>
                      <p style="color: #fff;">钛铝合金外壳</p>
                    </div>
                    <div>
                      <p style="color: grey;">低压保护电压</p>
                      <p style="color: #fff;">12V</p>
                    </div>
                    <div>
                      <p style="color: grey;">高低温</p>
                      <p style="color: #fff;">高温70℃ / 低温-25℃</p>
                    </div>
                    <div>
                      <p style="color: grey;">减震装置</p>
                      <p style="color: #fff;">三轴悬浮减震</p>
                    </div>
                  </div>
                </li>
                <li>
                  <span class="title">包装清单</span>
                  <div class="info">
                    <div>
                      <p style="color: #fff;">AIO-5 主机 x1</p>
                    </div>
                    <div>
                      <p style="color: #fff;">摄像头 x2</p>
                    </div>
                    <div>
                      <p style="color: #fff;">T10&T25梅花扳手 x2</p>
                    </div>
                    <div>
                      <p style="color: #fff;">球头座 x1</p>
                    </div>
                    <div>
                      <p style="color: #fff;">并线扣 x3</p>
                    </div>
                    <div>
                      <p style="color: #fff;">贴纸 x2</p>
                    </div>
                    <div>
                      <p style="color: #fff;">尼龙扎带 x10</p>
                    </div>
                    <div>
                      <p style="color: #fff;">魔术贴束线带 x4</p>
                    </div>
                    <div>
                      <p style="color: #fff;">车机线束 x1</p>
                    </div>
                    <div>
                      <p style="color: #fff;">摄像头线束 x1</p>
                    </div>
                    <div>
                      <p style="color: #fff;">说明书 x1</p>
                    </div>
                    <div>
                      <p style="color: #fff;">管夹变径套 x3</p>
                    </div>
                    <div>
                      <p style="color: #fff;">固定管夹 x1</p>
                    </div>
                    <div>
                      <p style="color: #fff;">4G物联卡 x1</p>
                    </div>
                  </div>
                </li>
              </ul>
            </el-tab-pane>
            <el-tab-pane label="购买" name="fourth">定时任务补偿</el-tab-pane>
          </el-tabs>
          <div style="width: 100%;">
            <el-row>
              <el-col :span="24">
                <div class="foot_top main">
                  <dl>
                    <dt>产品</dt>
                    <dd><a href="#">AIO-5智能车机</a></dd>
                    <dd><a href="#">CG-2手机支架</a></dd>
                    <dd><a href="#">XR-3行车记录仪</a></dd>
                    <dd><a href="#">G3胎压监测</a></dd>
                  </dl>
                  <dl>
                    <dt>服务支持</dt>
                    <dd><a href="#">常见问题</a></dd>
                    <dd><a href="#">教程视频</a></dd>
                    <dd><a href="/#/home/support_download/down_one">下载</a></dd>
                  </dl>
                  <dl>
                    <dt>关于我们</dt>
                    <dd><a href="#">我们的故事</a></dd>
                    <dd><a href="#">联系我们</a></dd>
                  </dl>
                  <dl>
                    <dt>业务合作</dt>
                    <dd><a href="/#/home/todealer">成为经销商</a></dd>
                  </dl>
                </div>
                <div class="foot_middle main">
                  <div>
                    <el-tooltip placement="top">
                      <div slot="content">
                        <img :src="wx_erwei" alt="二维码" style="transform: translateY(600px)">
                      </div>
                      <el-button style="border: 0; background: transparent">
                        <img :src="wx" alt="wx" style="width: 38px; height: 31px;">
                      </el-button>
                    </el-tooltip>
                    <el-tooltip placement="top" style="margin-left: 50px;">
                      <div slot="content">
                        <img :src="dy_erwei" alt="二维码" style="transform: translateY(600px)">
                      </div>
                      <el-button style="border: 0; background: transparent">
                        <img :src="dy" alt="dy" style="width: 32px; height: 35px;">
                      </el-button>
                    </el-tooltip>
                    <div class="email"></div>
                  </div>
                </div>
                <div class="foot_bottom">
                  <span style="color: #5E5E5E; font-size: 20px;">版权所有©深圳市骑技科技有限公司保留所有权利。</span>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      head_logo: require('../../public/images/head_logo.png'),
      input: '', // 搜索框
      img_one: require('../../public/images/aioproduct/one.png'),
      img_two: require('../../public/images/aioproduct/two.png'),
      img_three: require('../../public/images/aioproduct/three.png'),
      img_four: require('../../public/images/aioproduct/four.png'),
      img_five: require('../../public/images/aioproduct/five.png'),
      img_six: require('../../public/images/aioproduct/six.png'),
      img_seven: require('../../public/images/aioproduct/seven.png'),
      img_eight: require('../../public/images/aioproduct/eight.png'),
      img_night: require('../../public/images/aioproduct/night.png'),
      // tab2
      img_top: require('../../public/images/aioproduct/specs/44780f47211a8f8661af2783d584bf3@2x.png'),
      // 尾部
      wx_erwei: require('../../public/images/wx_erwei.png'),
      wx: require('../../public/images/aioproduct/wx.png'),
      dy_erwei: require('../../public/images/aio/dy.png'),
      dy: require('../../public/images/aioproduct/dy.png')
    }
  },
  methods: {
    // 鼠标经过放大镜图标
    searchMove() {
      let ele = document.querySelector('.search')
      ele.style.display = 'none'
      let iptEle = document.querySelector('.ipt')
      iptEle.style.display = 'inline-block'
    },
    // 鼠标从输入框离开
    iptLeave() {
      let ele = document.querySelector('.search')
      let iptEle = document.querySelector('.ipt')
      ele.style.display = 'inline-block'
      iptEle.style.display = 'none'
    },
    // 子组件通知父组件显示商品按钮
    showGoods() {
      this.$emit('clickGoods')
    }
  }
}
</script>
<style lang="less" scoped>
.nav {
  position: relative;
  width: 100%;
  // background: pink;
  height: 60px;
  line-height: 60px;
  // margin-left: 20%;
  .search {
    color: #fff;
    font-size: 20px;
    margin-left: 15px;
  }
  .ipt {
    position: absolute;
    top: 0;
    right: -200px;
    display: none;
    // display: inline-block;
    width: 200px;
    margin-left: 15px;
    animation: reWidth 1s;
  }
  @keyframes reWidth {
    0% {
      width: 0;
    }
    100% {
      width: 200px;
    }
  }
  .nav_menu {
    height: 100%;
    box-sizing: border-box;
    display: inline-block;
    padding-top: 5px;
  }
  a {
    color: #fff;
    font-size: 18px;
    margin-left: 20px;
  }
}
.img_resize {
  width: auto;
  height: auto;
  max-width: 200px;
  vertical-align: middle;
}
// tab分栏
::v-deep .el-tabs__nav {
  float: right;
}
.aio_img_resize {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
// tab2
.specs li {
  position: relative;
  display: block;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  text-align: left;
  span {
    position: relative;
    top: 15px;
    left: 20px;
    font-weight: 500;
    font-size: 30px;
    color: #fff;
  }
  .info {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: 30%;
    width: calc(100% - 200px);
    // height: 400px;
    // background-color: purple;
    div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 50%;
      height: 100px;
      // background-color: pink;
      text-align: left;
      p {
        line-height: 40px;
      }
    }
  }
}
// 尾部
.main {
  text-align: left;
  // padding: 0 20%;
}
.foot_top {
  padding-top: 80px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  dl,
  a {
    line-height: 40px;
    font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
    font-size: 15px;
    // color: #111111;
    color: grey;
  }
  dt {
    font-weight: 500;
    color: #fff;
  }
}
.foot_middle {
  height: 260px;
  padding-top: 180px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  .email {
    float: right;
    width: 300px;
    height: 200px;
    background: url('../../public/images/email.png') no-repeat;
    background-size: 100% auto;
  }
}
.foot_bottom {
  position: relative;
  height: 120px;
  // background-color: green;
  line-height: 120px;
  span {
    position: absolute;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
  }
}
</style>